.about-page{
    margin: 0 35px;
    h1{
        font-family: $familyV;
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        margin: 0;
    }

    &-content{
        &-title{
            font-family: $familyV;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            margin-top: 0;
            margin-bottom: 38px;
        }
        &-text{
            font-size: 13px;
            font-weight: 400;
            line-height: 25px;
        }
    }
    
}

@media only screen and (min-width: $desktop){
    .about-page{
        margin-top: 35px;
        margin-left: 220px;
        width: 35%;
        min-height: calc(100vh - (535px));
        h1{
            font-size: 30px;
        }
        .blinking-text{
            margin-bottom: 102px;
        }
        &-content{
            &-title{
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 14px;
            }
            &-text{
                font-size: 16px;
            }
        }
    }
}