@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikBlack.otf');
    font-weight: 600;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikBlackItalic.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikBold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikBoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikExtralight.otf');
    font-weight: 100;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikExtralightItalic.otf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikLight.otf');
    font-weight: 200;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikLightItalic.otf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikMedium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikMediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikRegular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/GraphikRegularItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Verdana';
    src: url('./assets/fonts/verdana-font-family/verdana.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Verdana';
    src: url('./assets/fonts/verdana-font-family/verdana-bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Verdana';
    src: url('./assets/fonts/verdana-font-family/verdana-bold-italic.ttf');
    font-weight: 700;
    font-style: italic;
}

@import "./assets/scss/variable.scss";
@import "./assets/scss/main.scss";
@import "./assets/scss/header.scss";
@import "./assets/scss/footer.scss";
@import "./assets/scss/about.scss";
@import "./assets/scss/collections.scss";
