.collection-page{
    
    &-desktop{
        display: none;
    }
    &-mobile{
        margin:28px 36px 0;
        &-title{
            h1{
                font-family: $familyV;
                font-size: 17px;
                font-weight: 700;
            }
            
        }
        &-content{
            &-nav{
                display: flex;
                p{ 
                    font-family: $familyV;
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 0;
                    margin-bottom: 39px;
                    &:not(:last-child){
                        margin-right: 38px;
                    }
                }
                .selected{
                    font-weight: 700;
                }
            }
            &-text{
                font-size: 13px;
                font-weight: 400;
                font-family: $familyG;
                line-height: 25px;
                .store-container{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .store-content{
                        width: 48%;
                        img{
                            width: 100%;
                        }
                        .product-title{
                            margin: 0;
                            font-size: 13px;
                        }
                        .product-contact-btn-container{
                            margin-bottom: 39px;
                            display: flex;
                            flex-wrap: wrap;
                            
                            a{
                                text-decoration: none;
                                color: #000;
                                width: 45%;
                                span{
                                    border-bottom: 1px solid black;
                                    font-size: 10px;
                                    font-family: $familyG;
                                    padding-bottom: 3px;
                                    
                                }
                               
                            }
                        }
                    }
                }
            }
            &-images{
                margin: 25px 0;
                .col-100{
                    img{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
                .col-50{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    img{
                        width: 48%;
                    }
                }
            }
            &-gallery{
                img{
                    width: 100%;
                }
            }
        }

    }
}

@media only screen and (min-width: $desktop){
    .collection-page{
        min-height: calc(100vh - (535px));
        &-mobile{
            display: none;
        }
        &-desktop{
            display: block;
            margin-left: 223px;
            margin-right: 138px;
            &-title{
                margin-bottom: 105px;
                h1{
                    font-size: 30px;
                }
            }
            &-content{
                display: flex;
                justify-content: space-between;
                margin-bottom: 106px;
                .left-section{
                    width: 45%;
                    margin-right: 52px;

                    .title{
                        font-family: $familyV;
                        font-weight: 700;
                        font-size: 20px;
                        margin-bottom: 14px;
                        margin-top: 0;
                    }
                    .text{
                        font-family: $familyH;
                        font-size: 16px;
                        line-height: 30px;
                        margin-bottom: 50px;
                        margin-top: 0;
                    }
                }
                .right-section{
                    margin-top: 40px;
                    > div {margin-bottom: 20px;}
                    .col-100{ 
                        img {width: 100%;}
                    }
                    .col-50 {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        img {width: 48%;}
                    }
                }
            }
            &-store{
                .title{
                    font-family: $familyV;
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 23px;
                    margin-top: 0;
                }
                .store-container{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .store-content{
                        width: 32%;
                        margin-bottom: 105px;
                        img{
                            width: 100%;
                        }
                        .product-title{
                            font-size: 17px;
                        }
                        .product-contact-btn-container{
                            a{
                                text-decoration: none;
                                color: #000;
                                &:not(:last-child){
                                    margin-right: 23px;
                                }
                                span{
                                    border-bottom: 1px solid #707070;
                                    padding-bottom: 5px;
                                    font-size: 10px;
                                }
                                &:hover{
                                    span{
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-gallery{
                img{
                    width: 100%;
                }
            }
        }
    }
}