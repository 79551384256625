.desktop-header{
    display: none;
}

.mobile-header{
    display: flex;
    justify-content: space-between;
    margin: 35px;
    &-right{
        .nav-underline{
            border-bottom: 2px solid;
            height: 20px;
        }
        .about-nav{
            margin-bottom: 15px;
            padding: 0;
        }
        .collection-tab-container{
            text-align: right;
            color: #000;
            .collections-nav{
                display: inline-block;
            }
            .collection-title{
                color: #707070;
                margin-bottom: 12px;
                &.selected{
                    color: #000;
                }
                &:hover{
                    font-weight: 700;
                    color: #000;
                }
            }
            .collections-tab{
                text-transform: uppercase;
                &-content{
                    display: flex;
                    flex-direction: column;
                }
            }
            .open{
                border-bottom: 2px solid;
                height: 20px;
                margin-bottom: 15px;
            }
        }

        /* Position and sizing of burger button */
        .bm-burger-button {
            position: fixed;
            width: 30px;
            height: 25px;
            right: 36px;
            top: 45px;
        }
        
        /* Color/shape of burger icon bars */
        .bm-burger-bars {
            background: #707070;
            height: 10% !important;
        }
        
        /* Color/shape of burger icon bars on hover*/
        .bm-burger-bars-hover {
            background: #a90000;
        }
        
        /* Position and sizing of clickable cross button */
        .bm-cross-button {
            height: 24px;
            width: 24px;
            top: 0 !important;
            right: 36px !important;
        }
        
        /* Color/shape of close button cross */
        .bm-cross {
            background: #bdc3c7;
            width: 1.5px !important;
            height: 25px !important;
            transform: rotate(50deg) !important;
            &:last-child{
                transform: rotate(-50deg) !important;
            }
        }
        
        /*
        Sidebar wrapper styles
        Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
        */
        .bm-menu-wrap {
            position: fixed;
            height: 100%;
            width: 100% !important;
        }
        
        /* General sidebar styles */
        .bm-menu {
            background: #ffffff;
            padding: 2.5em 1.5em 0;
            font-size: 1.15em;
            height: calc(100vh - 35px) !important;
        }
        
        /* Morph shape necessary with bubble or elastic */
        .bm-morph-shape {
            fill: #373a47;
        }
        
        /* Wrapper for item list */
        .bm-item-list {
            color: #b8b7ad;
            padding: 0.8em;
        }
        
        /* Individual item */
        .bm-item {
                display: flex !important;
                flex-direction: column;
                align-items: flex-end;
                a{
                    text-decoration: none;
                    color: #000;
                }
                .nav-contact-icons  {
                    a:not(:last-child){
                        margin-right: 26px;
                    }
                }
        }
        /* Styling of overlay */
        .bm-overlay {
            background: rgba(0, 0, 0, 0.3);
            display: none !important;
        }

    }
    &-left{
        a{
            text-decoration: none;
            color: #000;
        }
        span{
            font-family: $familyV;
            font-size: 20px;
            font-weight: 400;
        }
        .circle{
            height: 50px;
            width: 50px;
            border: solid 1px #707070;
            display: block;
            border-radius: 50%;
            cursor: pointer;
            margin-left: 20px;
            margin-top: 12px;
            &:hover{
                background-color: #F0F0F0;
            }
        }
    }
}


@media only screen and (min-width: $desktop) {
    .mobile-header{
        display: none;
    }
    .desktop-header{
        display: flex;
        justify-content: space-between;
        margin: 50px 40px;
        margin-bottom: 150px;
        &-left{
            a{
                text-decoration: none;
                color: #000;
            }
            span{
                font-size: 40px;
                font-family: $familyV;
            }
            .circle{
                height: 100px;
                width: 100px;
                border: solid 1px #707070;
                display: block;
                border-radius: 50%;
                cursor: pointer;
                margin-left: 55px;
                position: fixed;
                &:hover{
                    background-color: #F0F0F0;
                }
            }
        }
        &-right{
            &-nav{
                display: flex;
                justify-content: flex-end;
                padding-top: 20px;
                width: 290px;
                font-size: 15px;
                .nav-underline{
                    border-bottom: 2px solid;
                    height: 20px;
                }
                .collection-tab-container{
                    position: relative;
                    text-align: right;
                    .collections-nav{
                        cursor: pointer;
                        margin: 0;
                        display: inline-block;
                    }
                    .collections-tab{
                        position:absolute;
                        text-transform: uppercase;
                        right: -67px;
                        width: calc(100vw - 355px);
                        height: 100vh;
                        background-color: white;
                        z-index: 1;
                        &-content{
                            margin-top: 15px;
                            margin-right: 67px;
                            display: flex;
                            justify-content: space-between;
                            .collection-title{
                                &:hover{
                                    font-weight: 700;
                                    color: #000;
                                }
                            }
                            &.less-6{
                                justify-content: flex-end;
                            }
                            .column-1{
                                order: 2;
                            }
                            a{
                                display: block;
                                text-decoration: none;
                                font-size: 13px;
                                color: #707070;
                                &:not(:last-child){
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
                .open{
                    border-bottom: 2px solid;
                    height: 20px;
                }
                .about-nav{
                    text-decoration: none;
                    color: black;
                    margin-left: 35px;
                }
                .nav-contact-icons{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 57px;
                    a:not(:last-child){
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}