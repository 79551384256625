body{
    font-family: $familyG;
    .empty-div{
        min-height: calc(100vh - (535px));
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .blinking-text{
        animation:blinkingText 0.5s infinite;
        cursor: pointer;
        text-decoration: none;
        color: #000;
        margin-top: 22px;
        margin-bottom: 37px;
        display: block;
        font-size: 10px;
        font-weight: 400;
        font-family: $familyA;
    }
    @keyframes blinkingText{
        0%{     opacity: 10%;    }
        50%{    opacity: 40%; }
        100%{   opacity: 100%;  }
    }
    .selected{
        color: #000 !important;
        font-weight: 700;
    }
}
@media only screen and (min-width: $desktop){
    body{
        .blinking-text{
            font-size: 12px;
        }
        .home-page + .footer{
            display: none;
        }
    }
}