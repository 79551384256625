.footer{
    display: none;
    &-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 80px 40px 30px;
        .footer-logo{
            font-size: 13px;
            line-height: 25px;
            font-family: $familyV;
        }
        .copyrights{
            font-size: 13px;
            line-height: 25px;
            font-family: $familyV;
        }
        .footer-nav-icons{
            a:not(:last-child){
                margin-right: 18px;
            }
        }
    }
}

@media only screen and (min-width: $desktop) {
    .footer{
        display: block;
    }
}